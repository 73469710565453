<template>
  <div 
    class="hotlist-thrifter" 
    :class="hotOrCold === 'hot' ? 'hot': 'cold'"
    @click="$router.push({name: 'thrifterInfo', params:{id: id}})"
  >
    <div class="top">
      <div class="img-wrapper">
        <img :src="img">
      </div>
      <div class="hotness">
        <span>{{hotness}}%</span>
        <i class="icon" :class="hotOrCold === 'hot' ? 'icn-hot' : 'icn-cold'" />
      </div>
    </div>
    <div class="bottom">
      <p class="subtitle" v-html="subtitle"></p>
      <p class="info-link">Info <i class="icon icn-arrow-right" /></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HotlistThrifter',
  props: {
    id: Number,
    hotness: Number,
    name: String,
    title: String,
    subtitle: String,
    img: String,
    hotOrCold: String,
  },
  methods: {
  }
}
</script>

<style lang="stylus" scoped>
  .hotlist-thrifter
    position relative
    width: 100%
    height 42.7vw
    // background-image url("https://via.placeholder.com/414x375")
    margin 2.2vw 0
    border-radius 2.2vw
    font-family "Hind"
    padding 4.3vw
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor pointer

    &.hot {
      background-color #EE5859
    }
    &.cold {
      background-color #3A428C
    }

    .top
      display: flex;
      justify-content: space-between;
      align-items flex-start

      .img-wrapper {
        width 17.07vw
        height 17.07vw
        border-radius 50vw
        overflow hidden

        // img {
        //   display block
        // }
      }

      .hotness
        // padding 2.67vw 4.3vw
        color #fff
        font-weight 700
        font-size 4.8vw
        line-height 6.4vw
        border-radius 2.13vw
        display flex
        align-items: center;
        opacity .5

    .bottom
      width 100%
      display: flex;
      justify-content: space-between;

      p
        margin 0

      .info-link
        color #fff
        white-space: nowrap;
        font-size 4.3vw
        line-height 4.3vw
        font-weight 600
        align-self: center;
        opacity .5
        display flex
        align-items center

      .subtitle
        color #fff
        font-weight 700
        font-size 4.8vw
        line-height 6.4vw
        text-transform uppercase
        text-align left
        width 63.8%

  .icon
    display block
    width 11vw
    height 11vw
    transform scale(0.8)
    margin-left 1.5vw
    margin-top -1vw
    background-color: #fff;

    &.icn-cold
      mask-image: url('~@/assets/icons/cold.svg')
      margin-top: -2vw;
      margin-left: 0;
    &.icn-hot
      mask-image: url('~@/assets/icons/hot.svg')
      margin-top: -2.5vw;
      margin-left: -1vw;
    
    &.icn-arrow-right
      mask-image: url('~@/assets/icons/chevron_right.svg')
      width 6vw
      height 6vw
      
  
</style>