<template>
  <!-- :style="{'background-image': 'url('+img+')'}" -->
  <div 
    class="thrifter-preview" 
    @click="saveScrollPosition(); $router.push({name: 'thrifter', params:{id: id}})"
  >
    <div class="video-wrapper">
      <video 
        autoplay 
        muted 
        loop 
        class="video-preview" 
        playsinline
        :class="[videoVertical? 'video-vertical' : '', typeof videoZoomPosition === 'string' ? 'video-zoom-position-'+videoZoomPosition:'']" 
      >
        <!-- <source src="https://www.w3schools.com/howto/rain.mp4" type="video/mp4" playsinline> -->
        <source :src="short" type="video/mp4">
      </video>
    </div>
    <div class="top">
      <span class="hotness">{{hotness}}% Matching</span>
      <div class="icon-wrapper" v-if="isInHotList(id)">
        <i class="icon icn-hot" />
      </div>
    </div>
    <div class="bottom">
      <p class="title">{{name}}{{nameSuffix}}</p>
      <p class="subtitle">{{subtitle}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThrifterPreview',
  props: {
    id: Number,
    hotness: Number,
    name: String,
    nameSuffix: String,
    title: String,
    subtitle: String,
    img: String,
    short: String,
    video: String,
    videoVertical: Boolean,
    videoZoomPosition: String,
  },
  data() {
    return {
      state: this.$store.state,
    }
  },
  computed: {
    // compHotThrifter() {
    //   let hotIds = this.state.hotNotList.hot
    //   let hotDataList = []
    //   hotIds.forEach(id => {
    //     let thrifter = this.$store.matching.thrifterData.filter(x => x.id == id)[0]
    //     hotDataList.push(thrifter)
    //   });
    //   return hotDataList
    // },
  },
  methods: {
    isInHotList(id) {
      return this.state.hotNotList.hot.includes(id)
    },
    saveScrollPosition() {
      this.state.matching.scrollPosition = window.scrollY;
    },
  }
}
</script>

<style lang="stylus" scoped>
  .thrifter-preview
    position relative
    width: 100vw
    height: 110.4vw
    // background-image url("https://via.placeholder.com/414x375")
    margin 2.2vw 0
    border-radius 2.2vw
    font-family "Hind"
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden
    cursor pointer

    .video-wrapper
      width 100%
      height 100%
      position absolute
      overflow hidden
      z-index 0

      .video-preview
        width auto
        height 100%

        &.video-vertical
          width 100%
          height auto

        &.video-zoom-position-bottom 
          position absolute
          bottom -8%
          left 0
          right 0

    .top
      padding 6.4vw 4.3vw 0
      display: flex;
      // justify-content: flex-end;
      z-index 1

      .hotness
        padding 2.67vw 4.3vw
        color #EE5859
        background-color #fff
        font-weight 700
        font-size 4.8vw
        line-height 6.4vw
        border-radius 2.13vw
        z-index 1

      .icon-wrapper
        background-color #fff
        border-radius 100%
        z-index 0
        height 11.73vw
        width 11.73vw
        margin-left 2.13vw
        display flex
        // justify-content center
        // align-items center
        justify-content: flex-end;
        align-items: flex-end;
      
        .icon
          display block
          width 11vw
          height 11vw
          // transform scale(0.95)
          margin 0
          background-color: #EE5859;
          mask-size: contain;
          mask-repeat: no-repeat;

          &.icn-hot
            mask-image: url('~@/assets/icons/hot.svg')


    .bottom
      padding 0 4.3vw 6.4vw
      width 100vw
      position absolute
      bottom -1px
      z-index 1
      height: 50%;
      background: linear-gradient(0deg, rgba(13,14,33,0.8) 0%, rgba(13,14,33,0) 100%);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      p
        margin 0

      .title
        font-weight 700
        font-size 7.36vw
        line-height 6.4vw

      .subtitle
        font-weight 700
        font-size 5vw
        line-height 6.4vw
        text-transform uppercase
      
  
</style>