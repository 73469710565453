import Vue from 'vue'
// import router from './router'

const state = new Vue({
    data() {
      return {
        state: {
          iframeUrl: window.document.location.origin,  //window.document.location.hostname == 'localhost' ? 'http://localhost:8081' : 'https://thrifter.birkenbeul-digital.de/',
          allowCookies: localStorage.getItem('klaro') != null && localStorage.getItem('klaro').search('true') >= 0 ? true : false,
          profile: {
            leftOver: null,
            riskType: null,
            purpose: null,
            payoutType: null,
            sustainability: null,
          },
          answerIndexes: {
            leftOver: null,
            riskType: null,
            purpose: null,
            payoutType: null,
            sustainability: null,
          },
          answerPoints: {
            konto: 0,
            festgeld: 0,
            aktien: 0,
            anleihenfonds: 0,
            depot: 0,
            anleihen: 0,
            bausparen: 0,
            sachwerte: 0,
            // crypto: 0,
            rente: 0,
            kissen: 0,
          },
          showMeTheMoney: null,
          minThrifterOnHotlist: 1,
          hotNotList: {
            hot: [],
            not: []
          },
          matching: {
            isFirstTime: true,
            scrollPosition: 0,
          },
        },
        brokerData: {
          slug: null,
          firstName: null,
          lastName: null,
          email: null,
          phone: null,
          comapnyName: null,
          website: null,
          message: null,
          logo: null,
        },
        matching: {
          thrifterData: [
            {
              type: "konto",
              id: 122,
              hotness: 80,
              name: "Leia",
              nameSuffix: " bleibt locker mit",
              hotlistTitle: "Girokonto",
              subtitle: "dem GIROKONTO",
              img: "/static/video/leia.jpg",
              short: "/static/video/leia1.short.mp4",
              video: "/static/video/leia_denoised.mp4",
              videoVertical: true,
              videoZoomPosition:null,
              caption: "/static/video/leia.vtt",
              details: {
                title: "Konto (Giro, Tagesgeld) &amp; Sparbuch",
                content: "Für deine täglichen Zahlungen ist ein Girokonto unerlässlich. Für deinen Notgroschen sind Tagesgeldkonto und Sparbuch bestens geeignet. Hier kommst du jederzeit an deine Ersparnisse ran, z. B. wenn die Waschmaschine den Geist aufgibt.",
                infoList: [
                  {
                    title: "<strong>Was du wissen solltest:</strong>",
                    title2: "Girokonto",
                    list: [
                      "Kommt von <strong>italienisch „giro“</strong>, das heißt „Kreislauf“ oder „kreisen lassen“.",
                      "Bringt dir <strong>keine Zinsen</strong> auf die Ersparnisse.",
                      "Kostet je nach Bank <strong>zwei bis zehn Euro Gebühren pro Monat.</strong>",
                      "Bei Überziehung zahlst du <strong>bis zu 14 % Dispogebühren.</strong>",
                    ],
                  },
                  {
                    title: null,
                    title2: "Tagesgeldkonto",
                    list: [
                      "Deine Ersparnisse sind <strong>jederzeit verfügbar.</strong>",
                      "Ist in der Regel <strong>kostenfrei.</strong>",
                      "Der Zinssatz ist vergleichsweise niedrig, da dein Geld jederzeit verfügbar ist.",
                      "Benötigt zusätzlich ein <strong>Referenzkonto</strong> in Form eines Girokontos.",
                    ],
                  },
                  {
                    title: null,
                    title2: "Sparbuch",
                    list: [
                      "Lange Zeit <strong>der Klassiker beim Sparen.</strong>",
                      "Kostet dich <strong>keinerlei Gebühren.</strong>",
                      "Seit geraumer Zeit wird dein Sparbetrag kaum noch verzinst.",
                      "Du kannst monatlich <strong>bis zu 2.000 EUR</strong> abheben.",
                    ],
                  },
                ],
                ctas: [
                  "<strong>Könnte passen?</strong>",
                  "<strong>Hol dir mehr Infos!</strong>",
                ],
              },
              calcValues: {
                leftOver: [100, 100, 100],
                riskType: [0, 0, 100],
                purpose: [0, 0, 100],
                payoutType: [50, 0, 100],
                sustainability: [0, 100],
              }
            },
            {
              type: "festgeld",
              id: 251,
              hotness: 80,
              name: "Denise",
              nameSuffix: "'s Favorite:",
              hotlistTitle: "Festgeld",
              subtitle: "das FESTGELDKONTO",
              img: "/static/video/denise.jpg",
              short: "/static/video/denise.short.mp4",
              video: "/static/video/denise.mp4",
              videoVertical: true,
              videoZoomPosition:null,
              caption: "/static/video/denise.vtt",
              details: {
                title: "Festgeld (Festzinssparen) &amp; Sparbrief",
                content: "Beim Festgeld und Sparbrief werden zu Beginn eine bestimmte Laufzeit und ein bestimmter Zinssatz festgelegt. Für alle, die ihr Geld ganz ohne Risiko anlegen und dennoch Zinsen bekommen wollen.",
                infoList: [
                  {
                    title: "<strong>Was du wissen solltest:</strong>",
                    title2: "Festgeld",
                    list: [
                      "Laufzeiten von <strong>ein bis drei Jahren</strong> sind üblich.",
                      "In der Zeit ist dein Geld <strong>fest angelegt</strong> und du kannst es <strong>nicht abheben.</strong>",
                      "Je nach Laufzeit und Sparbetrag bekommst du <strong>bis zu 1,5 % Zinsen.</strong>",
                      "Eine <strong>sichere Möglichkeit</strong>, um <strong>kurz- oder mittelfristig</strong> zu sparen, z. B. für einen neuen Laptop.",
                    ],
                  },
                  {
                    title: null,
                    title2: "Sparbrief",
                    list: [
                      "Die Laufzeit beträgt zwischen <strong>einem und zehn Jahren.</strong>",
                      "In dieser Zeit kannst du <strong>nicht auf dein Geld zugreifen.</strong>",
                      "Die Verzinsung ist abhängig von der Laufzeit.",
                      "Eine <strong>sichere Sparform</strong> für Geld, das du über eine <strong>längere Zeit übrig</strong> hast und von dem du dir dann was gönnen willst, z. B. eine Weltreise.   ",
                    ],
                  },
                ],
                ctas: [
                  "<strong>Könnte passen?</strong>",
                  "<strong>Hol dir mehr Infos!</strong>",
                ],
              },
              calcValues: {
                leftOver: [0, 100, 100],
                riskType: [0, 0, 100],
                purpose: [0, 0, 100],
                payoutType: [100, 0, 25],
                sustainability: [0, 100],
              }
            },
            {
              type: "aktien",
              id: 381,
              hotness: 80,
              name: "Alex",
              nameSuffix: " schwört auf",
              hotlistTitle: "Aktien-Fonds / Aktien-ETFs",
              subtitle: "AKTIEN-FONDS & ETFS",
              img: "/static/video/alex.jpg",
              short: "/static/video/alex.short.mp4",
              video: "/static/video/alex.mp4",
              videoVertical: false,
              videoZoomPosition:null,
              caption: "/static/video/alex.vtt",
              details: {
                title: "Aktienfonds &amp; ETFs",
                content: "Aktienfonds &amp; ETFs bündeln die Wertpapiere einzelner Unternehmen in einem Topf. Bei ETFs sogar ohne Hilfe von Fondsmanagern. Die Gewinne können automatisch wieder zum Sparen angelegt oder regelmäßig ausgezahlt werden.",
                infoList: [
                  {
                    title: "<strong>Was du wissen solltest:</strong>",
                    title2: null,
                    list: [
                      "<strong>Aktienfonds</strong> werden aktiv von Fondsmanagern betreut.",
                      "Sie kosten zwischen <strong>0,5 und 3 % Gebühren pro Jahr.</strong>",
                      "Die Mindestanlage liegt <strong>zwischen 500 und 1.000 Euro</strong>, ein Sparplan beginnt <strong>ab 1 Euro</strong> im Monat.",
                      "<strong>ETFs</strong> („Exchange Traded Funds“ = börsengehandelte Indexfonds) bilden passiv einen Aktienindex nach, z. B. den Deutschen Aktienindex DAX.",
                      "ETFs kosten <strong>weniger Gebühren als Aktienfonds</strong>, weil keine Fondsmanager bezahlt werden: <strong>0,1 bis 0,5 % pro Jahr.</strong>",
                      "Je nach Depot liegen die Mindestanlagen bei <strong>25 oder 50 Euro</strong> pro ETF, Sparpläne starten ab <strong>1 Euro pro Monat.</strong>",
                      "Aktienfonds und ETFs können <strong>jederzeit ge- und verkauft</strong> werden.",
                      "Die Kurse von Aktienfonds und ETFs <strong>unterliegen börsenüblichen Schwankungen.</strong>",
                      "Deshalb solltest du eher <strong>mittel- bis langfristig anlegen.</strong>",
                    ],
                  },
                ],
                ctas: [
                  "<strong>Könnte passen?</strong>",
                  "<strong>Hol dir mehr Infos!</strong>",
                ]
              },
              calcValues: {
                leftOver: [25, 50, 100],
                riskType: [100, 50, 0],
                purpose: [100, 50, 0],
                payoutType: [100, 0, 50],
                sustainability: [100, 100],
              }
            },
            {
              type: "anleihenfonds",
              id: 455,
              hotness: 80,
              name: "Tessa",
              nameSuffix: " erklärt",
              hotlistTitle: "Anleihenfonds / Anleihen-ETF",
              subtitle: "ANLEIHEN-FONDS & -ETFS",
              img: "/static/video/tessa.jpg",
              short: "/static/video/tessa.short.mp4",
              video: "/static/video/tessa.mp4",
              videoVertical: true,
              videoZoomPosition:null,
              caption: "/static/video/alex.vtt",
              details: {
                title: "Anleihen-Fonds &amp; -ETFs",
                content: "Anleihen-Fonds &amp; -ETFs funktionieren wie ein Kredit: Du leihst Banken, Institutionen, Staaten oder Unternehmen Geld und kriegst dafür Zinsen. Sowohl Fonds als auch ETFs bündeln viele Anleihen in einem Topf und werden an der Börse gehandelt.",
                infoList: [
                  {
                    title: "<strong>Was du wissen solltest:</strong>",
                    title2: null,
                    list: [
                      "Anleihen-Fonds &amp; -ETFs unterliegen <strong>börsenüblichen Schwankungen.</strong>",
                      "ETF steht für <strong>„Exchange Traded Funds“</strong>, also an der Börse gehandelte Fonds.",
                      "ETFs <strong>bilden passiv einen Index</strong> nach, z. B. den Deutschen Anleihenindex REX.",
                      "Für beide Sparformen brauchst du ein <strong>Depot bei einer Bank.</strong>",
                      "Sparpläne gibt’s schon ab <strong>1 Euro monatlich.</strong>",
                      "Die jährlichen Gebühren liegen im Schnitt bei <strong>0,5 bis 1 %.</strong>",
                      "Passiv gemanagte Anleihen-ETFs sind mit rund <strong>0,2 % Gebühren</strong> günstiger.",
                      "Eignen sich gut, um <strong>langfristig</strong> zu sparen, z. B. für ein Auto oder eine Immobilie.",
                    ],
                  },
                ],
                ctas: [
                  "<strong>Könnte passen?</strong>",
                  "<strong>Hol dir mehr Infos!</strong>",
                ],
              },
              calcValues: {
                leftOver: [25, 50, 100],
                riskType: [0, 100, 0],
                purpose: [50, 100, 25],
                payoutType: [100, 0, 50],
                sustainability: [100, 100],
              }
            },
            {
              type: "depot",
              id: 587,
              hotness: 80,
              name: "Ricardo",
              nameSuffix: " geht aufs Ganze mit",
              hotlistTitle: "Depot mit Einzelaktien",
              subtitle: "einem DEPOT MIT EINZELAKTIEN",
              img: "/static/video/ricardo1.jpg",
              short: "/static/video/ricardo_short.mp4",
              video: "/static/video/ricardo_compressed.mp4",
              videoVertical: false,
              videoZoomPosition:null,
              caption: "/static/video/alex.vtt",
              details: {
                title: "Depot mit Einzelaktien",
                content: "Bei einem Depot mit Einzelaktien investierst du in Unternehmen, deren Produkte du gut kennst, die du selbst benutzt oder die gute Renditechancen versprechen. Hier solltest du mittel- oder langfristig anlegen, damit du Kurseinbrüche an der Börse aussitzen kannst.",
                infoList: [
                  {
                    title: "<strong>Was du wissen solltest:</strong>",
                    title2: null,
                    list: [
                      "Mit einer Aktie erwirbst du einen <strong>Anteil an einem Unternehmen.</strong>",
                      "Eine Aktie im Deutschen Aktienindex DAX kostet <strong>zwischen rund 10 und 300 Euro.</strong>",
                      "Zum Kaufen und Verkaufen brauchst du ein <strong>Wertpapier-Depot.</strong>",
                      "Pro Kauf werden <strong>Ordergebühren</strong> (je nach Broker 1 bis ca. 60 Euro) sowie <strong>Börsengebühren</strong> (je nach Börse bis rund 5 Euro) fällig.",
                      "Aktien sind <strong>risikoreicher als Aktienfonds</strong>, denn du setzt auf „einzelne Pferde“ und nicht auf die „ganze Herde“.",
                      "Beobachte vor dem Kauf deshalb längere Zeit die <strong>Kursentwicklungen.</strong>",
                      "Und bevor du loslegst: Lass dich von einem <strong>Finanzexperten</strong> beraten."
                    ],
                  },
                ],
                ctas: [
                  "<strong>Könnte passen?</strong>",
                  "<strong>Hol dir mehr Infos!</strong>",
                ],
              },
              calcValues: {
                leftOver: [0, 0, 100],
                riskType: [100, 0, 0],
                purpose: [100, 50, 0],
                payoutType: [100, 0, 25],
                sustainability: [100, 100],
              }
            },
            {
              type: "anleihen",
              id: 667,
              hotness: 80,
              name: "Rufus",
              nameSuffix: " mags sicher mit",
              hotlistTitle: "Anleihen",
              subtitle: "ANLEIHEN",
              img: "/static/video/julian_alex.jpg",
              short: "/static/video/julian_alex.short.mp4",
              video: "/static/video/julian_alex.mp4",
              videoVertical: true,
              videoZoomPosition:null,
              caption: "/static/video/alex.vtt",
              details: {
                title: "Anleihen",
                content: "Anleihen sind Kredite in Form von Wertpapieren: Du leihst einer Bank, einem Staat oder Unternehmen Geld und kriegst dafür Zinsen. Gut geeignet für alle, die mehr Rendite als mit Sparkonten erzielen wollen, aber keine zu hohen Risiken an der Börse eingehen möchten.",
                infoList: [
                  {
                    title: "<strong>Was du wissen solltest:</strong>",
                    title2: null,
                    list: [
                      "Anleihen heißen auch <strong>Rentenpapiere, Bonds, Obligationen, Schuldverschreibungen oder festverzinsliche Wertpapiere.</strong>",
                      "Sie können jederzeit an der <strong>Börse ge- und verkauft</strong> werden.",
                      "Die Mindestanlagen liegen üblicherweise bei <strong>100, 500 oder 1.000 Euro.</strong>",
                      "In manche Anleihen kann aber auch mit <strong>jedem Betrag</strong> investiert werden.",
                      "Es gibt große Unterschiede bei der Bonität, d.h. der <strong>Kreditwürdigkeit der Einrichtung</strong>, der das Geld geliehen wird.",
                      "Beispielsweise haben Staatsanleihen in der Regel eine <strong>bessere Bonität</strong> als Unternehmensanleihen.",
                      "Anleihen sind <strong>nicht über die Einlagensicherung</strong> von Banken geschützt.",
                      "Im Gegensatz zu Aktien ist festgelegt, <strong>wann die Rückzahlung erfolgt.</strong>",
                      "Auch die <strong>Höhe der Zinsen</strong> ist festgelegt."
                    ],
                  },
                ],
                ctas: [
                  "<strong>Könnte passen?</strong>",
                  "<strong>Hol dir mehr Infos!</strong>",
                ],
              },
              calcValues: {
                leftOver: [0, 0, 100],
                riskType: [0, 100, 50],
                purpose: [0, 100, 25],
                payoutType: [100, 0, 25],
                sustainability: [100, 100],
              }
            },
            {
              type: "bausparen",
              id: 707,
              hotness: 80,
              name: "Lili & Leni",
              nameSuffix: " habens verstanden:",
              hotlistTitle: "Bausparen",
              subtitle: "BAUSPAREN",
              img: "/static/video/bausparen.jpg",
              short: "/static/video/bausparen.short.mp4",
              video: "/static/video/bausparen.mp4",
              videoVertical: true,
              videoZoomPosition:null,
              caption: "/static/video/alex.vtt",
              details: {
                title: "Bausparen",
                content: "Mit einem Bausparvertrag legst du lange vor dem Immobilienkauf den finanziellen Grundstein für dein Wohneigentum und sparst rechtzeitig vor. Für alle, die von ihren eigenen vier Wänden träumen.",
                infoList: [
                  {
                    title: "<strong>Was du wissen solltest:</strong>",
                    title2: null,
                    list: [
                      "Beim Bausparen werden deine Ersparnisse <strong>sehr sicher angelegt.</strong>",
                      "Bausparen ist <strong>staatlich gefördert</strong> – bis zu mehreren Hundert Euro im Jahr.",
                      "Die Sparzinsen sind derzeit sehr niedrig.",
                      "Hast du genug angespart (etwa die Hälfte der Bausparsumme), erhältst du ein <strong>günstiges Darlehen</strong>",
                      "Bausparen ist eine <strong>sehr langfristige Sparmöglichkeit.</strong>",
                      "Wie lange und wie viel du sparen willst, <strong>entscheidest du.</strong>",
                      "Je mehr du anlegst, desto <strong>schneller kannst du einziehen.</strong>",
                    ],
                  },
                ],
                ctas: [
                  "<strong>Könnte passen?</strong>",
                  "<strong>Hol dir mehr Infos!</strong>",
                ],
              },
              calcValues: {
                leftOver: [100, 100, 100],
                riskType: [0, 0, 100],
                purpose: [0, 0, 100],
                payoutType: [100, 0, 0],
                sustainability: [0, 100],
              }
            },
            {
              type: "sachwerte",
              id: 897,
              hotness: 80,
              name: "Dirk",
              nameSuffix: " hat diesen Tipp:",
              hotlistTitle: "Sachwerte: Immobilien",
              subtitle: "IMMOBILIEN",
              img: "/static/video/bernd.jpg",
              short: "/static/video/bernd.short.mp4",
              video: "/static/video/bernd.mp4",
              videoVertical: true,
              videoZoomPosition:null,
              caption: "/static/video/alex.vtt",
              details: {
                title: "Immobilien",
                content: "Der Kauf einer Immobilie eignet sich sowohl für langfristigen Vermögensaufbau als auch für die Altersvorsorge. Vor der Finanzierung solltest du 15 bis 20 % des Kaufpreises als Eigenkapital ansparen, am besten mit einem monatlichen Sparplan. Den Rest finanzierst du über ein Darlehen, z. B. bei einer Bank.",
                infoList: [
                  {
                    title: "<strong>Was du wissen solltest:</strong>",
                    title2: null,
                    list: [
                      "Immobilien gelten als <strong>solide Geldanlage</strong> und als <strong>relativ krisenresistent</strong>.",
                      "Ihr Wert ist unabhängig von <strong>schwankenden Finanzmärkten.</strong>",
                      "Die Höhe der Kreditzinsen schwankt.",
                      "Jedoch <strong>steigen die Kaufpreise</strong> in beliebten Städten rasant.",
                      "Du brauchst viel Disziplin und Durchhaltevermögen, um <strong>langfristig für deine Wunsch-Immobilie zu sparen.</strong>",
                      "Dafür eignet sich z. B. ein <strong>Dauerauftrag bei einem Konto</strong> oder ein <strong>Sparplan mit Anleihen.</strong>",
                      "Nach dem Kauf funktioniert die Tilgung des Darlehens wie ein <strong>Sparplan – nur umgekehrt</strong>: mit regelmäßigen Zahlungen wird das Darlehen immer weniger und dir gehört immer mehr von deiner Immobilie.",
                      "Immobilien sind im wahrsten Sinne des Wortes „immobil“  und lassen sich <strong>nicht von heute auf morgen verkaufen.</strong>",
                    ],
                  },
                ],
                ctas: [
                  "<strong>Könnte passen?</strong>",
                  "<strong>Hol dir mehr Infos!</strong>",
                ],
              },
              calcValues: {
                leftOver: [0, 50, 100],
                riskType: [0, 0, 100],
                purpose: [0, 100, 25],
                payoutType: [100, 50, 50],
                sustainability: [0, 100],
              }
            },
            {
              type: "crypto",
              id: 963,
              hotness: 80,
              name: "Erik",
              nameSuffix: " riskiert es einfach",
              hotlistTitle: "Bitcoin, Kryptowährung",
              subtitle: "KRYPTOWÄHRUNG",
              img: "/static/video/erik_crypto.jpg",
              short: "/static/video/erik_crypto.short.mp4",
              video: "/static/video/erik_crypto.mp4",
              videoVertical: true,
              videoZoomPosition: "bottom",
              caption: "/static/video/alex.vtt",
              details: {
                title: "Kryptowährung",
                content: "Bitcoin, Ethereum und Co.: Kryptowährungen werden immer beliebter. Aufgrund ihrer Schwankungen eignen sie sich nicht, um auf was Bestimmtes zu sparen oder Geld fürs Alter zurückzulegen. Sie bieten die Chance auf schnelle Gewinne – und totale Verluste.",
                infoList: [
                  {
                    title: "<strong>Was du wissen solltest:</strong>",
                    title2: null,
                    list: [
                      "Um Kryptowährungen zu kaufen, brauchst du ein <strong>Depot bei einem Krypto-Broker.</strong>",
                      "Investieren kannst du mit einem <strong>Sparplan</strong> oder mit <strong>einzelnen Beträgen.</strong>",
                      "Kryptos können <strong>jederzeit gekauft und wieder verkauft werden.</strong>",
                      "Die Währungen werden von <strong>Staaten nicht reguliert</strong>, deswegen hast du <strong>keinen Anlegerschutz.</strong>",
                      "Wie viel du investierst, hängt von <strong>deiner persönlichen Risikofreude ab.</strong>",
                    ],
                  },
                ],
                ctas: [
                  "<strong>Könnte passen?</strong>",
                  "<strong>Hol dir mehr Infos!</strong>",
                ],
              },
              calcValues: {
                leftOver: [0, 50, 100],
                riskType: [100, 0, 0],
                purpose: [100, 0, 0],
                payoutType: [100, 0, 25],
                sustainability: [0, 100],
              }
            },
            {
              type: "rente",
              id: 108,
              hotness: 80,
              name: "Zsofi",
              nameSuffix: " sorgt vor",
              hotlistTitle: "Rentenversicherung - fondsgebunden",
              subtitle: "mit einer FONDSRENTE",
              img: "/static/video/zsofi.jpg",
              short: "/static/video/zsofi.short.mp4",
              video: "/static/video/zsofi.mp4",
              videoVertical: false,
              videoZoomPosition:null,
              caption: "/static/video/alex.vtt",
              details: {
                title: "Fondsrente",
                content: "Eine Fondsrente ist eine Altersvorsorge mit Investmentfonds. Sie bietet dir die Sicherheit einer späteren lebenslangen Rente und gleichzeitig Chancen auf Rendite an der Börse. Zudem ist sie staatlich gefördert. Eine gute Sparmöglichkeit für alle, die sich später mehr leisten wollen.",
                infoList: [
                  {
                    title: "<strong>Was du wissen solltest:</strong>",
                    title2: null,
                    list: [
                      "Eine Fondsrente schließt du bei <strong>einer Versicherung ab.</strong>",
                      "Du sparst <strong>monatlich einen Beitrag</strong>, der bei Fondsgesellschaften angelegt wird.",
                      "Die Wertentwicklung hängt von der <strong>Performance der Fonds</strong> ab.",
                      "Eine Fondsrente sollte <strong>mindestens 20 Jahre</strong> laufen.",
                      "Schon <strong>kleine Beträge</strong> können sich über die Zeit stark vermehren – durch den Zinseszinseffekt!",
                      "Nach Ablauf erhältst du eine <strong>lebenslange garantierte Rente.</strong>",
                      "Oder lässt dir all dein Geld <strong>auf einmal auszahlen.</strong>",
                      "Im Vergleich zu Aktien und ETF fallen während der gesamten Ansparphase <strong>keine Steuern</strong> an.",
                      "Und in der Auszahlphase profitierst du von einer <strong>geringeren Besteuerung.</strong>",
                    ],
                  },
                ],
                ctas: [
                  "<strong>Könnte passen?</strong>",
                  "<strong>Hol dir mehr Infos!</strong>",
                ],
              },
              calcValues: {
                leftOver: [25, 100, 100],
                riskType: [25, 100, 25],
                purpose: [50, 100, 25],
                payoutType: [100, 100, 50],
                sustainability: [100, 100],
              }
            },
            {
              type: "kissen",
              id: 203,
              hotness: 80,
              name: "Julian",
              nameSuffix: " versteckt sein Geld:",
              hotlistTitle: "Kopfkissen / Bankschließfach",
              subtitle: "ZUHAUSE",
              img: "/static/video/julian.jpg",
              short: "/static/video/julian.short.mp4",
              video: "/static/video/julian.mp4",
              videoVertical: true,
              videoZoomPosition:null,
              caption: "/static/video/alex.vtt",
              details: {
                title: "Geld zuhause verstecken",
                content: "Unterm Kopfkissen, im Kühlschrank oder unter der Matratze: Hättest du gedacht, dass fast 40 % aller Deutschen größere Mengen Bargeld in den eigenen vier Wänden aufbewahren? Denkst du auch darüber nach?",
                infoList: [
                  {
                    title: "<strong>Was du wissen solltest:</strong>",
                    title2: null,
                    list: [
                      "Du bist <strong>unabhängig von Bank-Öffnungszeiten.</strong>",
                      "Du musst dich <strong>nicht über niedrige Zinsen ärgern.</strong>",
                      "Du hast dein Geld <strong>immer parat</strong> – vorausgesetzt, du findest es wieder",
                      "Aber: dein Geld kann <strong>schnell gestohlen werden</strong>, Einbrecher wissen genau, wo sie suchen müssen!",
                      "Und: die <strong>Inflation frisst dein Geld auf</strong>.",
                    ],
                  },
                ],
                ctas: [
                  "<strong>Klingt doof?</strong>",
                  "<strong>Hol dir bessere Alternativen!</strong>",
                ],
              },
              calcValues: {
                leftOver: [100, 100, 100],
                riskType: [0, 0, 100],
                purpose: [0, 25, 100],
                payoutType: [50, 0, 50],
                sustainability: [0, 100],
              }
            },
            // {
            //   id: 362,
            //   hotness: 70,
            //   name: "Stefan",
            //   title: "Lorem ipsum dolor sit amet",
            //   subtitle: "Fondsgebundene Rentenversicherung",
            //   img: "https://via.placeholder.com/414x375/707070",
            //   short: "",
            //   video: ""
            // },
            // {
            //   id: 591,
            //   hotness: 10,
            //   name: "Annie",
            //   title: "Ich geh auf Nummer Safe :)",
            //   subtitle: "Bausparen",
            //   img: "https://via.placeholder.com/414x375/A9A9A9",
            //   short: "",
            //   video: ""
            // },
          ]
        },
      }
    },
    computed: {
      compAvailableNavigation() {
        let toReturn = ['profile']
        // let profile = this.state.profile
        if (this.state.showMeTheMoney) {
          toReturn.push('matching')
        }
        if (this.compTotalVotes >= this.state.minThrifterOnHotlist) {
          toReturn.push('hotlist')
        }

        return toReturn
      },
      compTotalVotes() {
        return this.state.hotNotList.hot.length + this.state.hotNotList.not.length
      },
    },
    created() {
      if (window.innerWidth < 768) {
        this.initKlaroConfig()
      }
    },
    methods: {
      setProfileValue(valName, val) {
        let tmpVal;
        if (valName === "leftOver") {
          tmpVal = val + '&nbsp;Euro';
        }else {
          tmpVal = val;
        }

        this.state.profile[valName] = tmpVal;
      },
      setAnswerIndexes(valName, index) {
        let tmpVal;
        if (valName === "leftOver") {
          if (index <= 50) {
            tmpVal = 0;
          }else if (index > 50 && index <= 100) {
            tmpVal = 1;
          }else {
            tmpVal = 2;
          }
        }else {
          tmpVal = index
        }

        this.state.answerIndexes[valName] = tmpVal
      },
      resetAnswers() {
        this.state.answerIndexes = {
          riskType: null,
          purpose: null,
          payoutType: null,
          sustainability: null,
          leftOver: null,
        };
        this.state.profile = {
          leftOver: null,
          payoutType: null,
          purpose: null,
          riskType: null,
          sustainability: null,
        };
      },
      calcHotness() {
        // reset isFirstTime to true, so that the user can see the results
        this.state.matching.isFirstTime = true;

        this.matching.thrifterData.forEach(elem => {
          this.state.answerPoints[elem.type] = 0
          for (const [key,value] of Object.entries(elem.calcValues)) {
            this.state.answerPoints[elem.type] += parseInt(value[this.state.answerIndexes[key]], 10)
          }
        });
        // console.warn(this.state.answerPoints);

        let answerLength = Object.entries(this.state.answerIndexes).length

        for (const [key, value] of Object.entries(this.state.answerPoints)) {
          console.log(key, value);
          this.matching.thrifterData.find(x => x.type == key).hotness = parseInt(value/answerLength, 10)
        }
        this.sortThrifters()
      },
      sortThrifters() {
        this.matching.thrifterData.sort((a,b) => b.hotness - a.hotness)
      },
      currentThrifterPositionInList(id) {
        let pos = this.matching.thrifterData.map(e => e.id).indexOf(id)
        console.warn({pos});
        return pos
      },
      getThrifterIdByindex(index) {
        return this.matching.thrifterData[index].id
      },
      toggleHot(id) {
        let _id = parseInt(id, 10)
        let index = this.state.hotNotList.hot.indexOf(_id);
        if (index < 0) {
          this.state.hotNotList.hot.push(_id)
        }else {
          this.state.hotNotList.hot.splice(index, 1)
        }
      },
      initKlaroConfig() {
        window.klaroConfig = {
          // With the 0.7.0 release we introduce a 'version' paramter that will make
          // it easier for us to keep configuration files backwards-compatible in the future.
          version: 1,
      
          // You can customize the ID of the DIV element that Klaro will create
          // when starting up. If undefined, Klaro will use 'klaro'.
          elementID: 'klaro',
      
          // You can override CSS style variables here. For IE11, Klaro will
          // dynamically inject the variables into the CSS. If you still consider
          // supporting IE9-10 (which you probably shouldn't) you need to use Klaro
          // with an external stylesheet as the dynamic replacement won't work there.
          styling: {
              theme: ['light', 'bottom', 'wide'],
          },
      
          // Setting this to true will keep Klaro from automatically loading itself
          // when the page is being loaded.
          noAutoLoad: false,
      
          // Setting this to true will render the descriptions of the consent
          // modal and consent notice are HTML. Use with care.
          htmlTexts: false,
      
          // Setting 'embedded' to true will render the Klaro modal and notice without
          // the modal background, allowing you to e.g. embed them into a specific element
          // of your website, such as your privacy notice.
          embedded: false,
      
          // You can group services by their purpose in the modal. This is advisable
          // if you have a large number of services. Users can then enable or disable
          // entire groups of services instead of having to enable or disable every service.
          groupByPurpose: false,
      
          // How Klaro should store the user's preferences. It can be either 'cookie'
          // (the default) or 'localStorage'.
          storageMethod: 'localStorage',
      
          // You can customize the name of the cookie that Klaro uses for storing
          // user consent decisions. If undefined, Klaro will use 'klaro'.
          cookieName: 'klaro',
      
          // You can also set a custom expiration time for the Klaro cookie.
          // By default, it will expire after 120 days.
          cookieExpiresAfterDays: 365,
      
          // You can change to cookie domain for the consent manager itself.
          // Use this if you want to get consent once for multiple matching domains.
          // If undefined, Klaro will use the current domain.
          //cookieDomain: '.github.com',
      
          // You can change to cookie path for the consent manager itself.
          // Use this to restrict the cookie visibility to a specific path.
          // If undefined, Klaro will use '/' as cookie path.
          //cookiePath: '/',
      
          // Defines the default state for services (true=enabled by default).
          default: false,
      
          // If "mustConsent" is set to true, Klaro will directly display the consent
          // manager modal and not allow the user to close it before having actively
          // consented or declines the use of third-party services.
          // mustConsent: false,
      
          // Show "accept all" to accept all services instead of "ok" that only accepts
          // required and "default: true" services
          acceptAll: true,
      
          // replace "decline" with cookie manager modal
          hideDeclineAll: true,
      
          // hide "learnMore" link
          hideLearnMore: false,
      
          // show cookie notice as modal
          noticeAsModal: true,
      
          // You can also remove the 'Realized with Klaro!' text in the consent modal.
          // Please don't do this! We provide Klaro as a free open source tool.
          // Placing a link to our website helps us spread the word about it,
          // which ultimately enables us to make Klaro! better for everyone.
          // So please be fair and keep the link enabled. Thanks :)
          disablePoweredBy: true,
      
          // you can specify an additional class (or classes) that will be added to the Klaro `div`
          //additionalClass: 'my-klaro',
      
          // You can define the UI language directly here. If undefined, Klaro will
          // use the value given in the global "lang" variable. If that does
          // not exist, it will use the value given in the "lang" attribute of your
          // HTML tag. If that also doesn't exist, it will use 'en'.
          lang: 'de',
      
          // You can overwrite existing translations and add translations for your
          // service descriptions and purposes. See `src/translations/` for a full
          // list of translations that can be overwritten:
          // https://github.com/KIProtect/klaro/tree/master/src/translations
      
          // Example config that shows how to overwrite translations:
          // https://github.com/KIProtect/klaro/blob/master/src/configs/i18n.js
          translations: {
              // If you erase the "consentModal" translations, Klaro will use the
              // bundled translations.
              de: {
                  privacyPolicyUrl: '/#/datenschutz',
                  consentNotice: {
                    title: "Cookie-Einstellungen",
                    // description: ""
                    learnMore: "Cookies auswählen",
                    privacyPolicy:{
                      name: "Datenschutzerklärung"
                    },
                  },
                  consentModal: {
                      description:
                          'Hier können Sie einsehen und anpassen, welche Information wir über Sie sammeln. Einträge die als "Beispiel" gekennzeichnet sind dienen lediglich zu Demonstrationszwecken und werden nicht wirklich verwendet.',
                  },
                  matomo: {
                      description: 'Sammeln von Besucherstatistiken',
                  },
                  purposes: {
                    analytics: 'Statistische Auswertungen',
                    functional: {
                      description: "Diese Dienste sind für die korrekte Funktion dieser Website unerlässlich. Sie können sie hier nicht deaktivieren, da der Dienst sonst nicht richtig funktionieren würde. ",
                      title: "Dienstbereitstellung"
                    },
                  },
              }
          },
      
          // This is a list of third-party services that Klaro will manage for you.
          services: [
            {
                // Each service should have a unique (and short) name.
                name: 'matomo',
    
                // If "default" is set to true, the service will be enabled by default
                // Overwrites global "default" setting.
                // We recommend leaving this to "false" for services that collect
                // personal information.
                default: true,
    
                // The title of your service as listed in the consent modal.
                title: 'Matomo/Piwik',
    
                // The purpose(s) of this service. Will be listed on the consent notice.
                // Do not forget to add translations for all purposes you list here.
                purposes: ['analytics'],
    
                // A list of regex expressions or strings giving the names of
                // cookies set by this service. If the user withdraws consent for a
                // given service, Klaro will then automatically delete all matching
                // cookies.
                cookies: [
                    // you can also explicitly provide a path and a domain for
                    // a given cookie. This is necessary if you have services that
                    // set cookies for a path that is not "/" or a domain that
                    // is not the current domain. If you do not set these values
                    // properly, the cookie can't be deleted by Klaro
                    // (there is no way to access the path or domain of a cookie in JS)
                    // Notice that it is not possible to delete cookies that were set
                    // on a third-party domain! See the note at mdn:
                    // https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie#new-cookie_domain
                    [/^_pk_.*$/, '/', 'klaro.kiprotect.com'], //for the production version
                    [/^_pk_.*$/, '/', 'localhost'], //for the local version
                    'piwik_ignore',
                ],
    
                // An optional callback function that will be called each time
                // the consent state for the service changes (true=consented). Passes
                // the `service` config as the second parameter as well.
                callback: function(consent, service) {
                    // This is an example callback function.
                    console.log(
                        'User consent for service ' + service.name + ': consent=' + consent
                    );
                    // To be used in conjunction with Matomo 'requireCookieConsent' Feature, Matomo 3.14.0 or newer
                    // For further Information see https://matomo.org/faq/new-to-piwik/how-can-i-still-track-a-visitor-without-cookies-even-if-they-decline-the-cookie-consent/
                    /*
                    if(consent==true){
                        _paq.push(['rememberCookieConsentGiven']);
                    } else {
                        _paq.push(['forgetCookieConsentGiven']);
                    }
                    */
                },
    
                // If "required" is set to true, Klaro will not allow this service to
                // be disabled by the user.
                required: false,
    
                // If "optOut" is set to true, Klaro will load this service even before
                // the user gave explicit consent.
                // We recommend always leaving this "false".
                optOut: false,
    
                // If "onlyOnce" is set to true, the service will only be executed
                // once regardless how often the user toggles it on and off.
                onlyOnce: true,
            },
      
          ],
      };
      }
      // setHot(id) {
      //   let _id = parseInt(id, 10)
      //   if (!this.state.hotNotList.hot.includes(_id)) {
      //     this.state.hotNotList.hot.push(_id)
          
      //     // remove from cold list
      //     let index = this.state.hotNotList.not.indexOf(_id);
      //     if (index >= 0) {
      //       this.state.hotNotList.not.splice( index, 1 );
      //     }
      //   }
      // },
      // setNot(id) {
      //   let _id = parseInt(id, 10)
      //   if (!this.state.hotNotList.not.includes(_id)) {
      //     this.state.hotNotList.not.push(_id)

      //     // remove from hot list
      //     let index = this.state.hotNotList.hot.indexOf(_id);
      //     if (index >= 0) {
      //       this.state.hotNotList.hot.splice( index, 1 );
      //     }
      //   }
      // },
    }
})

export const store = state