<template>
<!-- <div class="grid-y align-center content-grid no-overflow"> -->
<div class="matching">
  <div class="text-wrapper">
    <button-imprint />
    <p class="big-text" style="margin-top:6.4dvh">We show you the money: Dein Thrifter Matching</p>
  </div>
  <div ref="top" class="thrifter-wrapper">
    <p class="small-text">Lass dir von den Thriftern erklären, was hinter den Sparformen steckt, die am besten zu dir passen. Wenn du willst, vote für die Clips, die du besonders interessant findest, und setze sie auf deine Hotlist. Die Prozentangabe oben links gibt übrigens an, wie sehr die Sparformen mit deinen Angaben übereinstimmen.</p>
    <ThrifterPreview 
      v-for="thrifter in $store.matching.thrifterData" :key="thrifter.id"
      :id="thrifter.id"
      :hotness="thrifter.hotness"
      :name="thrifter.name"
      :nameSuffix="thrifter.nameSuffix"
      :title="thrifter.title"
      :subtitle="thrifter.subtitle"
      :img="thrifter.img"
      :short="thrifter.short"
      :video="thrifter.video"
      :videoVertical="thrifter.videoVertical"
      :videoZoomPosition="thrifter.videoZoomPosition"
    />
  </div>
  <BottomNav />
</div>
</template>

<script>
import BottomNav from '@/components/BottomNav.vue'
import ThrifterPreview from '@/components/ThrifterPreview.vue'
import buttonImprint from "@/components/ButtonImprint.vue"

export default {
  name: 'Matching',
  components: {
    BottomNav,
    ThrifterPreview,
    buttonImprint
  },
  data() {
    return {
      state: this.$store.state,
    }
  },
  computed: {

  },
  mounted() {
    // scroll to top only first time
    if (this.state.matching.isFirstTime) {
     window.scrollTo(0,1500);
      // this.$refs.top.scrollIntoView({behavior: 'smooth', block: 'start'});
      this.$smoothScroll({
        scrollTo: this.$refs.top,
        duration: 800,
        offset: 0,
        updateHistory: false,
        easingFunction: 'easeOutQuart',
      })
      this.state.matching.isFirstTime = false;
    }else {
      window.scrollTo(0, this.state.matching.scrollPosition);
    }
  },
  methods: {
  }
}
</script>

<style lang="stylus" scoped>
.matching
  min-height 100%
  width 100%
  font-family "Hind"
  background-color #0D0E21
  color #fff

  .text-wrapper 
    // padding 19.1vw 6.4vw 0
    padding 10vw 6.4vw 0
    text-align left
    position: fixed;
    z-index: 9;
    background-color: #0d0e21;
    
    .big-text 
      font-weight 700
      font-size 7.47vw
      line-height 9vw
  

  .thrifter-wrapper
    padding-bottom 32.5vw
    padding-top: 50.8vw;

    .small-text 
      font-weight 400
      font-size 4.3vw
      line-height 6vw
      padding: 0 6.4vw;
      text-align: left;
      // padding-top: 100px;

</style>