<template>
<!-- <div class="grid-y align-center content-grid no-overflow"> -->
<div class="thrifter-info">
  <div class="info-wrapper">
    <div class="top">
      <ButtonClose @click.native="goBack()" />
    </div>
    <div class="info-content">
      <p class="title" v-html="compThrifterData.details.title"></p>
      <p v-html="compThrifterData.details.content"></p>
      <div v-for="(item,index) in compThrifterData.details.infoList" :key="index">
        <p class="subtitle" v-if="item.title!=null" v-html="item.title"></p>
        <p class="subtitle" v-if="item.title2!=null" v-html="item.title2"></p>
        <ul v-for="(list,listIndex) in item.list" :key="listIndex">
          <li v-html="list"></li>
        </ul>
      </div>
    </div>
  </div>
  <p class="your-thrifter">Dein Thrifter</p>
  <div class="video-wrapper">
    <video autoplay loop id="myVideo" class="video-background" ref="video" playsinline muted="true" :key="compThrifterData.id">
      <!-- <source src="https://www.w3schools.com/howto/rain.mp4" type="video/mp4" playsinline> -->
      <source :src="compThrifterData.short" type="video/mp4">
    </video>
  </div>
  <div class="button-wrapper">
    <div class="cta-btn" @click="contactInfoVisible = true">
      <span class="large">Hier kannst du dich beraten lassen</span>
    </div>
    <div class="cta-btn" @click="$store.toggleHot($route.params.id); goBack();">
      <span class="large hollow">von Hotlist entfernen</span>
    </div>
  </div>
  <transition name="slide-in">
    <ContactView 
      v-if="contactInfoVisible" 
      :class="contactInfoVisible ? 'is-visible' : ''" 
      v-on:close-event="contactInfoVisible = false"
    />
  </transition>
</div>
</template>

<script>
// import ButtonBack from '@/components/ButtonBack.vue'
import ButtonClose from '@/components/ButtonClose.vue'
import ContactView from '@/components/ContactView.vue'

export default {
  name: 'ThrifterInfo',
  components: {
    // ButtonBack,
    ButtonClose,
    ContactView,
  },
  data() {
    return {
      state: this.$store.state,
      contactInfoVisible: false,
    }
  },
  computed: {
    compThrifterData() {
      return this.$store.matching.thrifterData.filter(obj => {
        return obj.id == this.$route.params.id;
      })[0]
    },

  },
  mounted() {
  },
  methods: {
    goBack() {
      // let _id = this.$route.params.id
      // this.$router.push({name: 'thrifter', params:{id: _id}})
      this.$router.push({name: 'hotlist'})
    },
  }
}
</script>

<style lang="stylus" scoped>
.thrifter-info
  width 100vw
  height auto
  position relative
  
  .your-thrifter 
    font-family "Hind"
    font-weight 700
    font-size 4.8vw
    line-height 6.4vw
    padding 0 6.4vw
    text-align left  
    text-transform uppercase
    margin-bottom 2.13vw
  
  .video-wrapper
    video
      width: 100vw;
      border-radius: 2vw;

  .info-wrapper 
    background-color #fff
    z-index 1
    transition: all 0.3s ease-out;
    overflow hidden

    .info-content
      font-family "Hind"
      font-weight 500
      font-size 4.8vw
      line-height 6.4vw
      color #000
      padding 19.2vw 6.4vw 0
      text-align left

      p
        margin-bottom 8.53vw

      .title 
        font-weight 700
        font-size 7.47vw
        line-height 8.96vw

      .subtitle
        font-weight 700
        margin-bottom 2.13vw

    .top
      padding: 6.4vw 6.4vw 4.3vw;
      display: flex;
      justify-content: flex-end;
      position fixed
      width 100vw

  .button-wrapper
    padding 0 6.4vw 17.1vw

    .cta-btn
      display: flex;
      justify-content: center;
      margin-top: 2.13vw;
      cursor pointer

      span
        font-family "Hind"
        font-weight 600
        background-color #EE5859
        color #fff
        font-size 4.3vw
        line-height 6.5vw
        text-align center
        border-radius 100px
        padding 2.13vw 6.4vw

        &.hollow 
          background-color transparent
          color #EE5859
          border 2px solid #EE5859
        
        &.large 
          width 100%
      
.slide-in-enter-active {
  transition: all .3s ease;
}
.slide-in-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-in-enter, .slide-in-leave-to {
  transform: translateY(15vw);
  opacity: 0;
}

.hidden {
  opacity 0
  pointer-events none
}


</style>