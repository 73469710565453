<template>
  <div class="nav-bottom">
    <div class="nav-wrapper">
      <div class="icon-wrapper" 
        :class="$route.name === 'questionnaire' ? 'active' : ''"
        @click="setRoute('profil')"
      >
        <i class="icon icn-profile" />
        <span>Profil</span>
      </div>
      <div class="icon-wrapper" 
        :class="[($route.name === 'matching' ? 'active' : ''), ($store.compAvailableNavigation.includes('matching') ? '' : 'disabled')]"
        @click="setRoute('matching')"
      >
        <i class="icon icn-matching" />
        <span>Matching</span>
      </div>
      <div class="icon-wrapper" 
        :class="[($route.name === 'hotlist' ? 'active' : ''), ($store.compAvailableNavigation.includes('hotlist') ? '' : 'disabled')]"
        @click="setRoute('hotlist')"
      >
        <i class="icon icn-hotlist" />
        <span class="amount2" v-if="state.hotNotList.hot.length > 0">{{state.hotNotList.hot.length}}</span>
        <span>Hotlist</span>
      </div>
      <!-- <span 
        :class="[($route.name === 'matching' ? 'active' : ''), ($store.compAvailableNavigation.includes('matching') ? '' : 'disabled')]"
        @click="setRoute('matching')"
      >Matching</span>
      <span 
        :class="[($route.name === 'hotlist' ? 'active' : ''), ($store.compAvailableNavigation.includes('hotlist') ? '' : 'disabled')]"
        @click="setRoute('hotlist')"
      >Hotlist<sup class="amount">{{state.hotNotList.hot.length}}</sup></span> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomNav',
  data() {
    return {
      state: this.$store.state,
    }
  },
  props: {
  },
  methods: {
    setRoute(where) {
      switch (where) {
        case 'profil':
          this.$router.push({name:'questionnaire', params:{step:1}}).catch(()=>{})
          break;
        case 'matching':
          this.$router.push({name:where}).catch(()=>{})
          break;
        case 'hotlist':
          this.$router.push({name:where}).catch(()=>{})
          break;
      
        default:
          break;
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .nav-bottom
    position: fixed;
    bottom: 0;
    z-index 10

    .nav-wrapper
      width: 100vw
      background-color #0D0E21
      display flex
      justify-content: space-between;
      // padding 4.267vw 8.5vw 6.4vw
      // padding 4.267vw 0 6.4vw
      padding 3.2vw 0 4.2vw
      
      span
        color #fff
        font-family "Hind"
        font-weight 600
        font-size 4.27vw
        line-height 4.27vw
        text-align center
        // padding-bottom 2.3vw
        // opacity .3
        
        &.active
          // opacity 1
          // border-bottom 2px solid #EE5859
          color: #EE5859;
        
        &.disabled
          opacity .3
        
        .amount 
          color #EE5859
          font-size 2.9vw
          line-height 2.9vw
          padding-left 1vw

    .icon-wrapper
      height 14.93vw
      width 14.93vw
      display: flex;
      flex-grow: 1
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      transition: all 0.3s ease-out;
      filter: drop-shadow(0px 0px 5px #000);
      cursor pointer

      &.disabled {
        opacity: 0.25;
        pointer-events: none;
      }
      
      .icon
        display block
        width 8.53vw
        height 8.53vw
        // width: 32px
        // height: 32px
        background-color #fff
        background-repeat: no-repeat;
        mask-repeat: no-repeat
        mask-size: contain;

        &.icn-profile
          // background-image url('~@/assets/icons/profile.svg')
          mask-image: url('~@/assets/icons/profile.svg')
        &.icn-matching
          // background-image url('~@/assets/icons/hot_new.svg')
          mask-image: url('~@/assets/icons/hot_new.svg')
        &.icn-hotlist
          // background-image url('~@/assets/icons/hotlist_empty.svg')
          mask-image: url('~@/assets/icons/hotlist_empty.svg')

          &.filled
            // background-image url('~@/assets/icons/hotlist_filled.svg')
            mask-image: url('~@/assets/icons/hotlist_filled.svg')

      .amount2
        position: absolute;
        // right: 20%;
        right: 12.3vw
        bottom: 50%;
        background-color: #EE5859;
        border-radius: 100%;
        width: 4vw;
        height: 4vw;
        font-size: 0.75rem;
        font-weight: 900;
        color: #fff !important;
        align-self: center;
        padding-top .25vw;

      &.active
        .icon
          background-color #EE5859
        
        span
          color #EE5859
</style>