var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-slider',{attrs:{"min":_vm.min,"max":_vm.max,"dot-size":32,"processStyle":'#fdeeee',"lazy":true,"interval":10,"tooltip":'always'},on:{"change":function($event){_vm.$store.setProfileValue('leftOver', _vm.leftOverSlider); _vm.$store.setAnswerIndexes('leftOver', _vm.leftOverSlider)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(ref){
var value = ref.value;
var focus = ref.focus;
return [_c('div',{class:['custom-tooltip', { focus: focus }],style:({
        transform: ("translateX(" + (_vm.calcTooltipPosition(value)) + "%)")
      })},[_vm._v(_vm._s(value)+" Euro")])]}},{key:"dot",fn:function(ref){
      var focus = ref.focus;
return [_c('div',{class:['custom-dot', { focus: focus }]})]}},{key:"process",fn:function(ref){
      var style = ref.style;
return [_c('div',{staticClass:"vue-slider-process custom-class",style:([style])})]}}]),model:{value:(_vm.leftOverSlider),callback:function ($$v) {_vm.leftOverSlider=$$v},expression:"leftOverSlider"}})}
var staticRenderFns = []

export { render, staticRenderFns }