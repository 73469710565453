<template>
  <div class="announcement-view is-visible">
    <div class="top-wrapper">
      <ButtonClose v-on:click-event="$emit('close-event')"/>
    </div>
    <div class="content-wrapper">
      <div class="bgimg"></div>
      <div class="text-wrapper">
        <p class="strong white uppercase medium mb-0">Du brennst fürs Beraten?</p>
        <p class="title strong">
          Werde Ansprech-partner!
        </p>
        <p class="white">Du bist Finanz- oder Versicherungsberater*in und willst deine junge Zielgruppe zeitgemäß erreichen? Dann hol dir jetzt deine eigene THRIFTER-Version und mach dich zum zentralen Ansprechpartner rund ums Sparen und Vorsorgen.</p>
        <a href="https://landing-staging.thrifter.de/start/signup" target="_blank" class="cta-btn">
          <span>Jetzt registrieren</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonClose from '@/components/ButtonClose.vue'

export default {
  name: 'ContactView',
  components: {
    ButtonClose,
  },
  data() {
    return {
      state: this.$store.state,
    }
  },
  props: {
  },
  methods: {
    
  }
}
</script>

<style lang="stylus" scoped>
  .announcement-view
    height 0
    width 100%
    position fixed
    bottom 0
    background-color #EE5859
    z-index 10
    transition: all 0.3s ease-out;
    box-shadow 0 -5px 15px rgba(0, 0, 0, 0.66);
    overflow scroll
    z-index 999;

    .bgimg 
      background-image: url('/static/thrifter_white_flame.png');
      background-size: 82.5vw auto;
      background-repeat: no-repeat;
      background-position: center 12.6vh;
      opacity 0.13;
      height: 100vh;
      width: 100vw;
      position absolute;
      top 0;
      left 0;
      z-index -1;

    &.is-visible
      height 100vh
      transition: all 0.3s ease-out;

    .top-wrapper
      padding 6.4vw 6.4vw 0
      margin-bottom 4.3vw
      display: flex;
      justify-content: flex-end;

    .content-wrapper
      margin-top: 15vh;
      z-index 1000;
      p
        padding 0 11.1vw
        font-family "Hind"
        color #0D0E21
        font-weight 500
        font-size 4.8vw
        line-height 6.4vw
        text-align left

        &.title
          font-weight 900
          margin-bottom: 5vh;
          font-size: 12.73vw;
          line-height: 11.73vw;

        &.medium
          font-size 4.8vw


        &.strong
          font-weight 700
          // text-transform uppercase

        &.mb-0
          margin-bottom: 0;

        &.white
          color #fff

        &.uppercase
          text-transform uppercase
      
      .cta-btn {
        font-size: 4.3vw;
        color #fff;
        font-weight 700;
        background-color: #0D0E21;
        width: 80vw;
        display: flex
        margin: 5vh auto;
        height: 15vw;
        justify-content: center;
        align-items: center;
        border-radius 10vw;
      }

</style>