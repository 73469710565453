<template>
  <div class="button-close" @click="$emit('click-event')">
    <span class="btn-close"></span>
  </div>
</template>

<script>
export default {
  name: 'ButtonClose',
  data() {
    return {
      state: this.$store.state,
    }
  },
  props: {
  },
  methods: {
    
  }
}
</script>

<style lang="stylus" scoped>
  .button-close
    background-color #0D0E21
    border-radius 50%
    width 10.67vw
    height 10.67vw
    display: flex;
    justify-content: center;
    align-items: center;
    z-index 1
    cursor pointer

    .btn-close
      width: 25px;
      height: 25px;
      background-color #fff
      mask-image: url('~@/assets/icons/close.svg')
</style>