<template>
<div class="questionnaire" :class="compBgName">
  <!-- <img src="" /> -->
  <button-imprint :iconColorClass="compIconColor" />
  <div class="padding-wrapper">
    <div class="top-nav">
      <ButtonBack :class="$route.params.step == 0 ? 'hidden' : ''" @click.native="goBack()"/>
      <div class="pagination-wrapper">
        <span 
          v-for="i in compTotalSteps" :key=i
          class="pagination-step"
          :class="$route.params.step >= i ? 'active' : '' || $route.params.step == 'summary' ? 'active' : ''"
        >
        </span>
      </div>
      <div class="dummy"></div>
    </div>
    <div class="intro-wrapper" v-if="compBgName === 'dark'">
      <p class="intro-1">Hello. Thriften ist englisch für „sparen“. THRIFTER deine App, mit der du die Sparform findest, die zu dir passt.</p>
      <p class="intro-2">Dafür möchte dich THRIFTER etwas näher kennenlernen. Erzähl uns was über dich – ganz schnell und easy 😁. Keine Sorge, es wird auch nichts dauerhaft gespeichert.</p>
      <div class="btn-wrapper">
        <router-link class="btn start" :to="{name:'questionnaire', params:{step:1}}">
          <p>Lass uns loslegen</p>
        </router-link>
        <!-- <div class="btn start">
          <p>Lass uns loslegen</p>
        </div> -->
      </div>
    </div>
    <div class="question-wrapper" v-if="compBgName !== 'dark'">
      <p class="summary" v-html="compSummary"></p>
      <div class="btn-confirm" v-if="$route.params.step === 'summary'">
        <span class="large" @click="$store.calcHotness(); findNextStep('matching')">Show Me The Money</span>
      </div>
      <div class="btn-confirm" v-if="$route.params.step === 'summary'">
        <span class="large hollow" @click="$store.resetAnswers(); findNextStep(1)">Nochmal von vorn</span>
      </div>
      <div class="answers" v-if="$route.params.step !== 'summary'">
        <div v-if="compCurrentStep.type==='select'">
          <p v-for="(answer, index) in compCurrentStep.values" :key="answer+index"
            @click="setAnswer(answer, compCurrentStepName, index)"
          >
            {{answer}}
          </p>
        </div>
        <!-- <div v-if="compCurrentStep.type==='selectMulti'">
          <p class="info">Wähle gerne 2 Antworten aus.</p>
          <p v-for="(answer, index) in compCurrentStep.values" :key="answer"
            @click="setAnswer(index)"
          >
            {{answer}}
          </p>
          <div class="btn-confirm" v-if="state.profile.purpose.length > 0">
              <span class="hollow" @click="nextStep('summary')">{{compCurrentStep.btn}}</span>
            </div>
        </div> -->
        <div v-if="compCurrentStep.type=='slider'">
            <custom-slider :min="compCurrentStep.values.min" :max="compCurrentStep.values.max" :init="this.compSliderInit" />
            <div class="btn-confirm" :class="state.profile.leftOver !== null ? '' : 'disabled'">
              <span @click="findNextStep()">{{compCurrentStep.btn}}</span>
            </div>
        </div>
      </div>
    </div>
  </div>
  <BottomNav v-if="$route.params.step != 0" style="padding:0;" />
</div>
</template>

<script>
import BottomNav from '@/components/BottomNav.vue'
import CustomSlider from '@/components/CustomSlider.vue'
import ButtonBack from '@/components/ButtonBack.vue'
import buttonImprint from "@/components/ButtonImprint.vue"

export default {
  name: 'Questionnaire',
  components: {
    BottomNav,
    CustomSlider,
    ButtonBack,
    buttonImprint
  },
  data() {
    return {
      state: this.$store.state,
      steps: {
        // ageRange: {
        //   id: 1,
        //   type: "select",
        //   quest: "Du bist ",
        //   suffix: "",
        //   values: [
        //     "... zwischen 18 und 24. ",
        //     "... zwischen 25 und 31. ",
        //     "... zwischen 32 und 38. ",
        //     "... älter als 38. ",
        //   ]
        // },
        leftOver: {
          id: 1,
          type: "slider",
          quest: "Ich kann pro Monat ",
          suffix: " sparen. ",
          values: {
            min: 10,
            max: 250
          },
          btn: "Passt so"
        },
        riskType: {
          id: 2,
          type: "select",
          quest: "Ich bin eher ",
          suffix: "",
          values: [
            "... risikofreudig drauf. ",
            "... der Typ Spaß mit Sicherheitsnetz. ",
            "... auf Nummer sicher unterwegs. "
          ]
        },
        purpose: {
          id: 3,
          type: "select",
          quest: "Ich will ",
          suffix: "",
          values: [
            "... ratzfatz reich werden und mir jederzeit was gönnen. ",
            "... lääänger sparen für genug Kohle im Alter. ",
            "... mein Geld sicher zurücklegen – für Urlaub, Wohnung, Familie. "
          ]
        },
        payoutType: {
          id: 4,
          type: "select",
          quest: "Mein Geld möchte ich später ",
          suffix: "",
          values: [
            "... einmalig als fetten Batzen kassieren. ",
            "... Monat für Monat entspannt ausgezahlt bekommen. ",
            "... hin und wieder in größerem Umfang zur Verfügung haben. "
          ]
        },
        sustainability: {
          id: 5,
          type: "select",
          quest: "Nachhaltigkeit ist für mich ",
          suffix: "",
          values: [
            "... ein Muss, auch beim Sparen und Vorsorgen. ",
            "... nicht so wichtig beim Thema Sparen. "
          ]
        },
        // purpose: {
        //   id: 5,
        //   type: "selectMulti",
        //   quest: "Du brauchst Geld für ",
        //   suffix: "",
        //   values: [
        //     "... Ich will mir was gönnen, also Handy, Urlaub etc. ",
        //     "... Auto, Haus etc. Ich will mir später was Größeres leisten. ",
        //     "... Vermögen, Vorsorge etc. Ich will genug Kohle im Alter haben. "
        //   ],
        //   shortValues: [
        //     "Handy, Urlaub etc. ",
        //     "Auto, Haus und so weiter. ",
        //     "Vermögen und Vorsorge. "
        //   ],
        //   btn: "Passt, weiter"
        // },

      },
      // selectedAnswers: {
      //   ageRange: null,
      //   leftOver: null,
      //   riskType: null,
      //   sustainability: null,
      //   purpose: []
      // },
    }
  },
  computed: {
    compBgName() {
      let toReturn = this.$route.params.step == 0 ? 'dark' : 'light'
      return toReturn
    },
    compCurrentStep() {
      let step = this.$route.params.step
      let keys = Object.keys(this.steps)
      let stepName = keys[step-1]

      let toReturn
      if (stepName != null) {
        toReturn = this.steps[stepName]
      }

      return toReturn;
    },
    compCurrentStepName() {
      let step = this.$route.params.step
      let keys = Object.keys(this.steps)
      let stepName = keys[step-1]

      return stepName;
    },
    compSummary() {
      let step = this.$route.params.step
      if (step == 'summary') {step = 5}
      let index = step-1
      let keys = Object.keys(this.steps)
      // let currentKey = keys[index];
      let summary = "";
      
      // get all previous answers and querstions and append to string
      for (let i=0; i<=index; i++) {
        let quest = this.steps[keys[i]].quest;
        let suffix = this.steps[keys[i]].suffix;
        let answer = this.state.profile[keys[i]]
        let step = i+1;
        if (answer === null || answer.length === 0) {
          answer = "..."
          suffix = ""
        }
        let wrappedAnswer = "<a href='#/profil/"+step+"'"+ this.$options._scopeId +">"+answer+"</a>"
        summary += quest + wrappedAnswer + suffix;
      }
      return summary
    },
    compTotalSteps() {
      let keys = Object.keys(this.steps)
      return keys.length
    },
    compSliderInit() {
      return this.state.profile.leftOver != null ? parseInt(this.state.profile.leftOver.split(' Euro')[0], 10) : 10
    },
    compIconColor() {
      let toReturn = '';
      if (this.$route.params.step > 0) {
        toReturn = 'dark'
      }
      return toReturn
    }
  },
  mounted() {
    if (this.$route.params.step == 0) {
      // this.findNextStep(0);
    }else {
      this.findNextStep();
    }
  },
  methods: {
    goBack() {
      let currentStep = this.$route.params.step;
      let next = currentStep
      if (currentStep == 'summary') {
        next = 5; 
      }else {
        next = next -1 
      }

      if (next < 0) {
        return null
      }
      this.$router.push({params:{step:next}}).catch(()=>{})
    },
    setAnswer(answer, stepName, answerIndex) {
      let currentType = this.compCurrentStep.type
      if (currentType === "select") {
        this.$store.setProfileValue(this.compCurrentStepName, answer.substring(4));
        this.$store.setAnswerIndexes(stepName, answerIndex)
        // this.selectedAnswers[this.compCurrentStepName] = answer.substring(4);
      }else if (currentType === "selectMulti") {
        this.$store.setProfileValue(this.compCurrentStepName, this.steps.purpose.shortValues[answer]);
      }else {
        this.$store.setProfileValue(this.compCurrentStepName, answer);
        // this.selectedAnswers[this.compCurrentStepName] = answer
      }
      
      // this.nextStep();
      this.findNextStep();
    },
    // nextStep(skipTo = null) {
    //   if (this.$route.params.step <5) {
    //     let step = parseInt(this.$route.params.step, 10)
    //     let nextStep = step+1
    //     this.$router.push({params:{step:nextStep}}).catch(()=>{})
    //     return;
    //   }else if (skipTo == null){
    //     skipTo = "summary"
    //   }
    //   switch (skipTo) {
    //     case "summary":
    //       this.$router.push({params:{step:"summary"}}).catch(()=>{})
    //       break;
    //     case "matching":
    //       this.state.showMeTheMoney = true
    //       this.$router.push({name:'matching', params:{step:"summary"}}).catch(()=>{})
    //       break;
    //     default:
    //       break;
    //   }
    // },
    findNextStep(skipTo = null) {
      if (this.$route.params.step <5 && skipTo == null) {
        // leftOver, riskType, purpose, payoutType, sustainability
        let nextStep = 0
        if (this.state.profile.sustainability == null) {
          nextStep = 5
          if (this.state.profile.payoutType == null) {
            nextStep = 4
            if (this.state.profile.purpose == null) {
              nextStep = 3
              if (this.state.profile.riskType == null) {
                nextStep = 2
                if (this.state.profile.leftOver == null) {
                  nextStep = 1
                }
              }
            }
          }
        }else {
          nextStep = "summary"
        }
        this.$router.push({params:{step:nextStep}}).catch(()=>{})
        return;
      }else if (skipTo == null){
        skipTo = "summary"
      }
      switch (skipTo) {
        case 0:
          this.$router.push({params:{step:0}}).catch(()=>{})
          break;
        case 1:
          this.$router.push({params:{step:1}}).catch(()=>{})
          break;
        case "summary":
          this.$router.push({params:{step:"summary"}}).catch(()=>{})
          break;
        case "matching":
          this.state.showMeTheMoney = true
          this.$router.push({name:'matching'}).catch(()=>{})
          break;
        default:
          break;
      }
    },
    prevStep() {
      if (this.$route.params.step >0) {
        let step = parseInt(this.$route.params.step, 10)
        let prevStep = step-1
        this.$router.push({params:{step:prevStep}}).catch(()=>{})
      }
    },
  }
}
</script>

<style lang="stylus" scoped>
.questionnaire
  min-height 100%
  width 100%
  font-family "Hind"

  &.dark 
    background-color #0D0E21
    color #fff
  
  &.light 
    background-color #F5F5F5
    color #0D0E21

    .hidden
      opacity 0
      pointer-events none

  .padding-wrapper
    padding 15vw 6.4vw 15.6vw

    .top-nav
      margin-bottom 4.3vw
      display flex
      align-items center
      justify-content space-between

      .pagination-wrapper
        display flex

        .pagination-step
          border 2px solid #0D0E21
          border-radius 50%
          width 2.13vw
          height 2.13vw
          display block
          margin 0 2.13vw

          &.active
            background-color #0D0E21

      .dummy
        width 10.67vw
        height 10.67vw

  .intro-wrapper 
    // padding 5.2vw 6.4vw 15.6vw
    text-align left

    .intro-1 
      font-weight 500
      font-size 4.8vw
      line-height 6.4vw
      margin-bottom 13vw

    .intro-2 
      font-weight 700
      font-size 7.1vw
      line-height 8.8vw
  
  .btn-wrapper 
    padding 0 20.9vw
    // position absolute
    bottom 10.67vw

    .btn.start
      background-color #EE5859
      color: #fff
      font-weight 600
      font-family "Hind"
      font-size: 4.26vw
      line-height 4.26vw
      text-align center
      display: flex;
      justify-content: center;
      align-items: center;
      height: 13.87vw;
      border-radius: 10vw;
      margin-top 15.2vw;
      width: 45.4vw

      p
        margin 0

.question-wrapper
  // margin 5.2vw 6.4vw 15.6vw
  text-align left
  font-family "Hind"
  font-weight 700
  font-size: 7.47vw
  line-height 8.96vw
  padding-bottom 33vw

  .summary 
    line-height 8.96vw

    a 
      color #EE5859

.answers 
  background-color #fff
  color #EE5859
  border-radius 32px
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.05);
  padding 6.4vw
  font-family "Hind"
  font-weight 600
  font-size 4.3vw
  line-height 4.3vw

  p 
    padding 2.2vw 0 1.5vw
    margin 0
    cursor pointer

    &.info
      color #0D0E21

.btn-confirm
  display: flex;
  justify-content: center;
  margin-top: 4.8vw;
  cursor: pointer;

  &.disabled
    opacity 0.5
    pointer-events none
    cursor not-allowed

  span
    font-family "Hind"
    font-weight 600
    background-color #EE5859
    color #fff
    font-size 4.3vw
    line-height 6.5vw
    text-align center
    border-radius 100px
    padding 2.13vw 6.4vw

    &.hollow 
      background-color transparent
      color #EE5859
      border 2px solid #EE5859
    
    &.large {
      width 100%
    }
</style>