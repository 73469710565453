<template>
  <vue-slider 
    v-model="leftOverSlider" 
    :min="min"
    :max="max"
    :dot-size="32"
    :processStyle="'#fdeeee'"
    :lazy="true"
    :interval="10"
    :tooltip="'always'"
    @change="$store.setProfileValue('leftOver', leftOverSlider); $store.setAnswerIndexes('leftOver', leftOverSlider)"
  >
    <template v-slot:tooltip="{ value, focus }">
      <div 
        :class="['custom-tooltip', { focus }]"
        :style="{
          transform: `translateX(${calcTooltipPosition(value)}%)`
        }"
      >{{ value }} Euro</div>
    </template>
    <template v-slot:dot="{ focus }">
      <div :class="['custom-dot', { focus }]"></div>
    </template>
    <template v-slot:process="{ style }">
      <div class="vue-slider-process custom-class" :style="[style]">
      </div>
    </template>
  </vue-slider>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'CustomSlider',
  components: {
    VueSlider
  },
  data() {
    return {
      state: this.$store.state,
      dragging: false,
      isDragging: false,
      leftOverSlider: this.init
    }
  },
  props: {
    min: Number,
    max: Number,
    init: Number,
  },
  computed: {
  },
  methods: {
    handleChange() {
      if (this.isDragging) return
      this.$store.calc()
      this.$emit("changeSlider", this.state.input[this.propQuestion].value);
    },
    calcTooltipPosition(val) {
      if (val == 10) return 20;
      if (val == 250) return -30;
      if (val == 240) return -15;
      if (val > 0 && val < 240) return 0;
    }
  }
}
</script>

<style lang="stylus">
// slider
.vue-slider
  padding 13.9vw 0 7vw!important

.custom-tooltip 
  transform translateY(-2vw)
  white-space nowrap
  font-weight: bold;

  // &.focus 
  //   font-weight: bold;

.vue-slider-dot-tooltip
  visibility visible

.custom-dot 
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #EE5859;
  transition: all .3s;
  box-shadow 0 0 0 8px #FDEEEE
  cursor grab
  
  .custom-dot:hover 
    // transform: rotateZ(45deg);
  
  .custom-dot.focus 
    // border-radius: 50%;

.vue-slider-process
  background-color #FDEEEE

.vue-slider-rail
  background-color #FDEEEE
</style>