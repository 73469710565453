<template>
<div>
  <!-- <div class="button-imprint" @click="$router.push({name: 'imprint'})"> -->
  <div class="button-imprint" @click="showMenu=true">
    <span class="btn-imprint" :class="iconColorClass"></span>
  </div>
  <div class="imprint-menu" :class="compShowMenu">
    <div class="top-wrapper">
      <!-- <ButtonClose @click.native="$router.go(-1)" /> -->
      <ButtonClose @click.native="showMenu=false" />
    </div>
    <div class="content-wrapper">
      <p class="big-text">Service</p>
      
      <div class="item-wrapper" @click="$router.push({name: 'contact'})">
        <p class="main-text">Kontakt</p>
        <p class="sub-text">Wie können wir dir helfen?</p>
      </div>
      
      <!-- <div class="item-wrapper" @click="$router.push({name: 'faq'})">
        <p class="main-text">Oft gefragt</p>
        <p class="sub-text">Antworten auf die häufigsten Fagen</p>
      </div> -->
      
      <p class="big-text">Rechtliches</p>
      
      <a href="https://www.dia-vorsorge.de/impressum/" target="_blank">
        <div class="item-wrapper">
          <p class="main-text">Impressum</p>
          <!-- <p class="sub-text">Informationen zu Lorem Ipsum</p> -->
        </div>
      </a>
      
      <div class="item-wrapper" @click="$router.push({name: 'privacy'})">
        <p class="main-text">Datenschutz</p>
        <!-- <p class="sub-text">Sicher Lorem Ipsum dolor</p> -->
      </div>
      
    </div>
  </div>
</div>
</template>

<script>
import ButtonClose from '@/components/ButtonClose.vue'

export default {
  name: 'ButtonImprint',
  components: {
    ButtonClose
  },
  data() {
    return {
      state: this.$store.state,
      showMenu: false,
    }
  },
  props: {
    iconColorClass: String 
  },
  computed: {
    compShowMenu() {
      let toReturn = 'hidden';
      if (this.showMenu) {
        toReturn = 'visible';
      }else {
        toReturn = 'hidden';
      }

      return toReturn;
    }
  },
  methods: {
  }
}
</script>

<style lang="stylus" scoped>
.button-imprint
  display: flex;
  position: absolute;
  right: 6.4vw;
  top: 6.4dvh;
  // top:2.46vh;
  width: 6.4vw;
  height: 6.4vw;
  z-index 1
  cursor pointer

  &.hidden 
    display none

  .btn-imprint
    width: 20px;
    height: 20px;
    background-color #fff
    mask-image: url('~@/assets/icons/imprint.svg')
    mask-repeat: no-repeat

    &.dark
      background-color: #0D0E21

.imprint-menu
  background-color: #fff
  z-index 111
  position fixed
  top 0
  height: 100dvh
  width: 100dvw
  transition: all .35s;

  &.hidden 
    opacity: 0
    left: 100%
    // height: 0
    pointer-events: none
    transition: all .175s;

  &.visible
    opacity: 1
    left: 0
    pointer-events: all


  .top-wrapper
    padding 6.4vw 6.4vw 0
    margin-bottom 4.3vw
    display: flex;
    justify-content: flex-end

  .content-wrapper
    padding: 6.4vw;

    .item-wrapper 
      border-bottom: 1px solid #E7E7E9;
      padding-block: 16px;
      position relative
      cursor pointer

      &::after
        content ""
        position absolute
        display block
        right 0
        top 33%
        width 6.8vw
        height 6.8vw
        background-color #0D0E21
        mask-image: url('~@/assets/icons/chevron_right.svg')
    
    p
      font-family "Hind"
      text-align: left;
      margin: 0;
      color: #0D0E21;
      
      a
        color: #0D0E21;

    .big-text
      font-size: 28px;
      line-height: 33.6px;
      font-weight: 700;
      margin-bottom: 16px;
      
      &:not(:first-child)
        margin-top: 76px;
    
    .main-text
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;

    .sub-text
      font-size: 16px;
      line-height: 22.5px;
      font-weight: 300;
</style>