<template>
<!-- <div class="grid-y align-center content-grid no-overflow"> -->
<div class="hotlist">
  <button-imprint />
    <!-- <div class="tab-wrapper">
      <div 
        class="tab-btn hot"
        :class="view == 'hot' ? 'active':''"
        @click="view='hot'"
      >
        <span>Pure Hotness</span>
        <i class="icon icn-hot" />
      </div>
      <div 
        class="tab-btn cold"
        :class="view == 'cold' ? 'active':''"
        @click="view='cold'"
      >
        <span>Cold As Ice</span>
        <i class="icon icn-cold" />
      </div>
    </div> -->
    <div class="thrifter-list">
      <p class="title">Die heißesten Sparformen für dich</p>
      <HotlistThrifter
        v-for="thrifter in compHotThrifter" :key="thrifter.id"
        :id="thrifter.id"
        :hotness="thrifter.hotness"
        :name="thrifter.name"
        :nameSuffix="thrifter.nameSuffix"
        :title="thrifter.title"
        :subtitle="thrifter.hotlistTitle"
        :img="thrifter.img"
        :hotOrCold="view"
      />
      <div class="cta-btn" @click="contactInfoVisible = true">
        <p>Hier kannst du dich beraten lassen</p>
      </div>
    </div>
    <transition name="slide-in">
      <ContactView 
        v-if="contactInfoVisible" 
        :class="contactInfoVisible ? 'is-visible' : ''" 
        v-on:close-event="contactInfoVisible = false"
      />
    </transition>
    <BottomNav />
</div>
</template>

<script>
import BottomNav from '@/components/BottomNav.vue'
import HotlistThrifter from '@/components/HotlistThrifter.vue'
import ContactView from '@/components/ContactView.vue'
import buttonImprint from "@/components/ButtonImprint.vue"

export default {
  name: 'Hotlist',
  components: {
    BottomNav,
    HotlistThrifter,
    ContactView,
    buttonImprint
  },
  data() {
    return {
      state: this.$store.state,
      view: 'hot',
      contactInfoVisible: false,
    }
  },
  computed: {
    compHotThrifter() {
      let hotIds = this.state.hotNotList.hot
      let hotDataList = []
      hotIds.forEach(id => {
        let thrifter = this.$store.matching.thrifterData.filter(x => x.id == id)[0]
        hotDataList.push(thrifter)
      });
      return hotDataList
    },
    // compColdThrifter() {
    //   let coldIds = this.state.hotNotList.not
    //   let coldDataList = []
    //   coldIds.forEach(id => {
    //     let thrifter = this.$store.matching.thrifterData.filter(x => x.id == id)[0]
    //     coldDataList.push(thrifter)
    //   });
    //   return coldDataList
    // },
    compThrifter() {
      if (this.view === 'hot') {
        return this.compHotThrifter
      }else {
        return this.compColdThrifter
      }
    }

  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="stylus" scoped>
.hotlist
  min-height 100%
  width 100%
  font-family "Hind"
  background-color #0D0E21
  height 100vh
  overflow hidden

  .title 
    font-weight 700
    color #fff
    font-size 7.47vw
    line-height 9vw
    text-align left
    padding 19.2vw 1.9vw 6.4vw
    margin 0

  .tab-wrapper
    display flex
    height 8.534vw

    .tab-btn
      width 100%
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom 2px solid rgba(255,255,255,.1)

      &.active 
        border-bottom 2px solid rgba(255,255,255,1)
      
      span
        font-weight 600
        color #fff
        font-size 4.3vw
        line-height 4.3vw
        margin-right 2.1vw

      .icon
        display block
        width: 11vw;
        height: 11vw;
        transform: scale(0.711);
        margin-left: -2vw;
        margin-top -1.5vw

        &.icn-cold
          background-color: #3A428C;
          mask-image: url('~@/assets/icons/cold.svg')
        &.icn-hot
          background-color: #EE5859;
          mask-image: url('~@/assets/icons/hot.svg')

  .thrifter-list
    padding 8.53vw 4.3vw 15vw
    overflow scroll
    height 100%

    .cta-btn
      padding 10vw 5vw
      // position: absolute;
      // bottom: 27vw;
      cursor pointer

      p
        font-family "Hind"
        font-weight 600
        font-size 4.3vw
        line-height 4.3vw
        text-align center
        color #fff
        padding 4.27vw 6.4vw
        border 2px solid #EE5859
        border-radius 50vw

</style>