<template>
<!-- <div class="grid-y align-center content-grid no-overflow"> -->
<div class="intro">
  <button-imprint />
    <lottie-animation 
        class="fire-anim"
        :animationData="require('@/assets/anim/fire2.json')"
        :loop="true"
        :autoplay="true"
        :speed="1"
    />
    <div class="logo-wrapper">
      <p class="beta-info">beta</p>
      <img class="logo" src="@/assets/img/thrifter_logo.png" />
    </div>
    <p class="slogan">Wir brennen fürs Sparen</p>
    <div class="makler-wrapper">
      <div v-if="false">
        <p class="makler-name">präsentiert von Martin Mustermakler</p>
        <p class="makler-firma">Musterfirma GmbH</p>
      </div>
    </div>
    <div class="tou-wrapper">
      <label class="container">Hiermit erkläre ich mich mit den <a href="#" @click.prevent="openPrivacy()">Datenschutzbestimmungen</a> einverstanden.
        <input type="checkbox" v-model="touAccepted">
        <span class="checkmark"></span>
      </label>
      <router-link class="btn start" :to="{name:'questionnaire', params:{step:0}}" :class="{'disabled': !touAccepted}">
        <p>Starten</p>
      </router-link>
      <!-- <div @click="start()" class="btn start">
        <p>Starten</p>
      </div> -->
    </div>
    <div class="footer dia">
      <img src="~@/assets/img/logo-full.svg" />
    </div>
</div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
import buttonImprint from "@/components/ButtonImprint.vue"
// import * as animationData from '@/assets/anim/fire.json'; 

export default {
  name: 'Intro',
  components: {
    LottieAnimation,
    buttonImprint
  },
  data() {
    return {
      state: this.$store.state,
      touAccepted: false,
    }
  },
  computed: {

  },
  mounted() {
  },
  methods: {
    openTou() {
      this.$router.push({name: 'tou'})
    },
    openPrivacy() {
      this.$router.push({name: 'privacy'})
    },
    start() {
      console.log("start clicked");
      document.body.requestFullscreen();
    },
  }
}
</script>

<style lang="stylus" scoped>
.intro
  background url('~@/assets/img/background@2x.png')
  background-size 100vh
  background-position center
  min-height 100%
  width 100%
  padding-top 15.2vw

  .logo 
    width 80vw
    position relative
    // top 35vw

  .fire-anim
    width 21vw
    margin 0 37vw

  .slogan, .makler-wrapper
    font-family: Hind;
    font-style: normal;
    font-weight: 600;
    font-size: 4.8vw;
    line-height: 4vw;
    text-align: center;
    letter-spacing: 0.045em;
    text-transform: uppercase;
    color: #fff;
    padding 4.533vw
    min-height: calc(2 * 2.808vh)

  .logo-wrapper 
    position relative

  .beta-info
    font-family: Hind;
    font-style: normal;
    font-weight: 600;
    font-size: 3vw;
    line-height: 4vw;
    text-align: right;
    letter-spacing: 0.045em;
    text-transform: uppercase;
    color: #fff;
    position absolute
    right: 3.5vw
    top: -2vw

  .makler-wrapper
    font-size: 3.8vw
    font-weight: 400
    padding: 0
    line-height: 6vw
    text-transform none

    p
      margin: 0

/* The container */
.container 
  display: block;
  position: relative;
  padding-left: 14vw;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

/* Hide the browsers default checkbox */
.container input 
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

/* Create a custom checkbox */
.checkmark 
  position: absolute;
  top: 0;
  left: 0;
  height: 10.67vw;
  width: 10.67vw;
  background-color: transparent;
  border-radius: 100px;
  border: 2px solid #fff;

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark 
  background-color: transparent;

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark 
  background-color: #EE5859;
  border-color: #EE5859;

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after 
  content: "";
  position: absolute;
  display: none;

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after 
  display: block;

/* Style the checkmark/indicator */
.container .checkmark:after 
  left: 3.3vw;
  top: 1.6vw;
  width: 3vw;
  height: 5vw;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);

.tou-wrapper 
  padding 0 8.55vw 28vw
  margin-top 15.2vw
  
  label.container 
    color: #fff
    font-family: "Hind"
    font-weight: 400
    font-size: 4.26vw;
    line-height 5vw;
    text-align left

    span
      font-weight 700
      text-decoration-line: underline;
    a
      font-weight 700
      text-decoration-line: underline;
      color: #fff;

  .btn.start
    background-color #EE5859
    color: #fff
    font-weight 600
    font-family "Hind"
    font-size: 4.26vw
    line-height 4.26vw
    text-align center
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    height: 7.8vh;
    height: 13.87vw;
    border-radius: 100px;
    margin-top 7vh;
    transition: opacity 0.3s ease-in-out;

    &.disabled
      opacity: 0.5
      cursor: not-allowed
      pointer-events: none

    p
      margin 0

.footer.dia 
  background-color #F5F5F5
  height 13.5vh
  position: fixed;
  bottom: 0;
  width: 100%;
  display flex
  justify-content center

  img
    width: 46.3vw;
</style>